import React, { useState, useEffect } from "react";
import SplashScreen from "../components/SplashScreen";
import LetterPullup from "../components/LetterPullup";
import Footer from "../footer/Footer";
import axios from "axios";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [animateText, setAnimateText] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
      setAnimateText(true);
      setShowImage(true);
      setShowButton(true);
    }, 4500);

    return () => clearTimeout(timer);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    city: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!formData.name.match(/^[a-zA-Z ]+$/)) {
      newErrors.name = "Please enter a valid name (Alphabets Only)";
    }

    // Phone validation
    if (!formData.phone.match(/^\d{10}$/)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
    }

    // City validation
    if (!formData.city.match(/^[a-zA-Z ]+$/)) {
      newErrors.city = "Please enter a valid city (Alphabets Only)";
    }

    // Email validation
    if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Message validation
    if (!formData.message.match(/^[a-zA-Z0-9\s-]+$/)) {
      newErrors.message = "Please enter a brief about your requirement";
    }

    setErrors(newErrors);
    console.log("Errors:", newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setSubmitSuccess(null);
    try {
      const response = await axios.post(
        "https://tinyurl.com/swioapi",
        formData
      );
      toast.success(
        "Form submitted successfully! Our team will get in touch with you shortly."
      );
      setSubmitSuccess(true);
      console.log(response.data);
    } catch (error) {
      toast.error("Failed to submit the form. Please try again.");
      setSubmitSuccess(false);
      console.error("There was a problem with the Axios request:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="home">
      <ToastContainer /> {/* Add ToastContainer here */}
      {showSplash && <SplashScreen setShowSplash={setShowSplash} />}
      {!showSplash && (
        <div className="home-start">
          <div
            className={`home-start-left ${
              animateText ? "animate" : "home-start-left"
            }`}
          >
            <h3>one stop solutions</h3>
            <h1 id="headh1">
              a quick break <span className="mobile">&</span>
              <span className="desktop">
                and <br className="mobile" />
              </span>{" "}
              <LetterPullup words="we will be back" />
            </h1>

            <button
              id="form-button"
              className={`${
                showButton ? "slide-in-button" : "slide-in-button"
              }`}
            >
              <a href="#conatct-formx">get in touch &#8599;</a>
            </button>
          </div>
          <div
            className={`home-start-right ${
              showImage ? "slide-in" : "slide-in"
            }`}
          >
            <img
              loading="lazy"
              src="https://cdn.pixabay.com/photo/2017/08/02/00/49/people-2569234_1280.jpg"
              alt=""
            />
          </div>
        </div>
      )}
      {!showSplash && (
        <div id="conatct-formx">
          <div className="form">
            <div className="left">
              <h3>Let's Connect🤝🏻</h3>
              <p>Time to Start the Conversation.</p>
            </div>
            <div className="right">
              <form id="form" onSubmit={handleSubmit}>
                <div className="form-input-container">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder=" "
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <label htmlFor="name">NAME</label>
                  {errors.name && (
                    <p className="error-message">{errors.name}</p>
                  )}
                </div>

                <div className="form-input-container">
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    id="phone"
                    placeholder=" "
                    required
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <label htmlFor="phone">PHONE</label>
                  {errors.phone && (
                    <p className="error-message">{errors.phone}</p>
                  )}
                </div>

                <div className="form-input-container">
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    id="city"
                    placeholder=" "
                    required
                    value={formData.city}
                    onChange={handleChange}
                  />
                  <label htmlFor="city">CITY</label>
                  {errors.city && (
                    <p className="error-message">{errors.city}</p>
                  )}
                </div>

                <div className="form-input-container">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder=" "
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <label htmlFor="email">EMAIL</label>
                  {errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>

                <div className="form-input-container" id="text-area-container">
                  <textarea
                    name="message"
                    id="message"
                    rows="3"
                    className="form-control"
                    style={{ resize: "none" }}
                    placeholder="BRIEF ABOUT YOUR REQUIREMENT"
                    required
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <p className="error-message">{errors.message}</p>
                  )}
                </div>

                <button
                  type="submit"
                  id="form-submit-btn"
                  className="submit"
                  disabled={submitSuccess === true}
                >
                  {loading ? (
                    <Loader />
                  ) : submitSuccess === true ? (
                    "Form Submitted"
                  ) : submitSuccess === false ? (
                    "Failed to Submit. Try Again"
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {!showSplash && <Footer />}
    </div>
  );
};

export default Home;
